<template>
  <b-overlay :show="loading">
    <div class="formBoder">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Customer" vid="customer_id" rules="required|min_value:1">
              <b-form-group
                label-for="customer_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Customer
              </template>
                <v-select
                  id="customer_id"
                  v-model="form.customer_id"
                  :reduce="op => op.value"
                  :options="customerList"
                  label="text"
                  :state="errors[0] ? false : (valid ? true : null)"
                  placeholder="Select"
                >
                </v-select>
              <div class="d-block invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Subscription Plan" vid="subcription_plan_id" rules="required|min_value:1">
              <b-form-group
                label-for="subcription_plan_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Subscription Plan
              </template>
              <b-form-select
                plain
                v-model="form.subcription_plan_id"
                :options="subscriptionPlanList"
                id="subcription_plan_id"
                @input="getSubscriptionPlanWiseTotal(form.subcription_plan_id)"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Grand Total" vid="grand_total" rules="required" v-slot="{ errors }">
              <b-form-group
                id="grand_total"
                label="Grand Total"
                label-for="grand_total"
              >
              <template v-slot:label>
                Grand Total
              </template>
                <b-form-input
                  id="grand_total"
                  v-model="form.grand_total"
                  type="text"
                  placeholder="Enter grand Total"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  readOnly
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="VAT Amount" vid="vat_percent" rules="required" v-slot="{ errors }">
              <b-form-group
                id="vat_percent"
                label="VAT Amount"
                label-for="vat_percent"
              >
              <template v-slot:label>
                VAT Percentage
              </template>
                <b-form-input
                  id="vat_percent"
                  v-model="form.vat_percentage"
                  placeholder="Enter Vat Amount"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  readonly
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="VAT Amount" vid="vat_amount" v-slot="{ errors }">
              <b-form-group
                id="vat_amount"
                label="VAT Amount"
                label-for="vat_amount"
              >
              <template v-slot:label>
                VAT Amount
              </template>
                <b-form-input
                  id="vat_amount"
                  disabled
                  v-model="form.vat_amount"
                  placeholder="Enter Vat Amount"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Discount" vid="discount" :rules="`required|min_value:1|max_value: ${form.grand_total}`" v-slot="{ errors }">
              <b-form-group
                id="discount"
                label="Discount"
                label-for="discount"
              >
              <template v-slot:label>
                Discount
              </template>
                <b-form-input
                  id="discount"
                  v-model="form.discount"
                  min="0.1"
                  placeholder="Enter discount"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  @input="updatePayableAmount()"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
                <!-- <div v-if="form.discount > form.grand_total" class="text-danger">
                  Discount must be less than grand total
                </div> -->
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Payable Amount" vid="payable_amount" rules="required" v-slot="{ errors }">
              <b-form-group
                id="payable_amount"
                label="Payable Amount"
                label-for="payable_amount"
              >
              <template v-slot:label>
                Payable Amount
              </template>
                <b-form-input
                  id="payable_amount"
                  v-model="form.payable_amount"
                  placeholder="Enter payable amount"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  readOnly
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Payment Status" vid="payment_status_id" rules="required|min_value:1">
              <b-form-group
                label-for="payment_status_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Payment Status
              </template>
              <b-form-select
                plain
                v-model="form.payment_status_id"
                :options="paymentStatuses"
                id="payment_status_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Payment Media" vid="payment_media_id" rules="required|min_value:1">
              <b-form-group
                label-for="payment_media_id"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                Payment Media
              </template>
              <b-form-select
                plain
                v-model="form.payment_media_id"
                :options="paymentMediaList"
                id="payment_media_id"
                :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Payment Date" vid="payment_date" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="payment_date">
                <template v-slot:label>
                  Payment Date
                </template>
                <flat-pickr
                  id="payment_date"
                  v-model="form.payment_date"
                  class="form-control"
                  placeholder="select Date"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :config="flatPickrConfig"
                />
                <div class="d-block invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col lg="6" md="6" sm="6" xs="12">
            <ValidationProvider name="Transction" vid="transction_id" rules="required" v-slot="{ errors }">
              <b-form-group
                id="transction_id"
                label="Transction Id"
                label-for="transction_id"
              >
              <template v-slot:label>
                Transction Id
              </template>
                <b-form-input
                  id="transction_id"
                  v-model="form.transction_id"
                  placeholder="Enter Transction Id"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
      <div class="row mt-3">
        <div class="col-sm-3"></div>
        <div class="col text-right">
            <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
            &nbsp;
            <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
        </div>
      </div>
     </b-form>
    </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import RestApi, { apiBaseURL } from '@/config'

export default {
  props: ['editItem'],
  mixins: {
    // helperMixin
  },
  components: {
    flatPickr, vSelect
  },
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      form: {
        customer_id: '',
        subcription_plan_id: 0,
        grand_total: 0,
        payment_date: '',
        vat_percentage: 0,
        vat_amount: 0,
        discount: 0,
        payable_amount: 0,
        payment_status_id: 0,
        payment_media_id: 0,
        transction_id: ''
      },
      errors: [],
      valid: null,
      loading: false,
      customerList: [],
      subscriptionPlanList: [],
      paymentStatuses: [],
      paymentMediaList: [],
      flatPickrConfig: {
        maxDate: 'today',
        dateFormat: 'd-m-Y'
      }
    }
  },
  created () {
    this.getActiveVatPercentage()
    if (this.editItem) {
      this.form = JSON.parse(this.editItem)
      this.form.payment_date = this.dDateReal(this.editItem.payment_date)
    }
  },
  mounted () {
    this.getCustomerList()
    this.getSubscriptionPlanList()
    this.getPaymentMediaData()
    this.getStatusesWithGroups()
  },
  computed: {
  },
  methods: {
    async submitData () {
      this.loading = true
      let result = ''
      if (this.form.id) {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/update_invoice_data', this.form)
      } else {
        result = await RestApi.postData(apiBaseURL, 'admin/ajax/store_invoice_data', this.form)
      }
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
        this.loading = false
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getCustomerList () {
      RestApi.getData(apiBaseURL, 'admin/ajax/get_user_all_list', null).then(response => {
        if (response.success) {
          var data = response.data
          this.customerList = data.filter(obj => obj.value !== null && obj.text !== null) // Exclude items with null values
            .map((obj, index) => {
              return { value: obj.value, text: obj.text + '-' + obj.phone }
            })
        } else {
          this.customerList = []
        }
      })
    },
    getPaymentMediaData () {
      RestApi.getData(apiBaseURL, 'admin/ajax/get_payment_media_all_list', null).then(response => {
        if (response.success) {
          var data = response.data
          this.paymentMediaList = data.filter(obj => obj.value !== null && obj.text !== null) // Exclude items with null values
            .map((obj, index) => {
              return { value: obj.value, text: obj.text }
            })
        } else {
          this.paymentMediaList = []
        }
      })
    },
    getSubscriptionPlanList () {
      RestApi.getData(apiBaseURL, 'admin/ajax/get_subscription_plan_all_list', null).then(response => {
        if (response.success) {
          var data = response.data
          this.subscriptionPlanList = data.filter(obj => obj.value !== null && obj.text !== null) // Exclude items with null values
            .map((obj, index) => {
              return { value: obj.value, text: obj.text, price: obj.plan_price }
            })
        } else {
          this.subscriptionPlanList = []
        }
      })
    },
    getStatusesWithGroups () {
      RestApi.getData(apiBaseURL, 'admin/ajax/get_statuses_with_groups', null).then(response => {
        if (response.success) {
          if (response.status_groups) {
            this.paymentStatuses = this.makeSelectList(this.getStatuseByGroupCode(response.status_groups, 'payment_statuses'))
          }
        } else {
          this.paymentStatuses = []
        }
      })
    },
    async getActiveVatPercentage () {
      this.loading = true
      const response = await RestApi.getData(apiBaseURL, 'admin/ajax/get_active_vat_percentage')
      this.loading = false
      if (response.success) {
        this.form.vat_percentage = response.vatPercent
      }
    },
    getSubscriptionPlanWiseTotal (planId) {
      const subscriptionPlan = this.subscriptionPlanList.find(item => item.value === planId)
      if (subscriptionPlan) {
        this.form.payable_amount = 0
        this.form.grand_total = subscriptionPlan.price
        this.form.vat_amount = parseInt((this.form.grand_total * this.form.vat_percentage) / 100).toFixed(2)
        this.form.payable_amount = this.form.grand_total + parseInt(this.form.vat_amount)
      }
    },
    updatePayableAmount () {
      const discount = this.form.discount ? this.form.discount : 0
      this.form.payable_amount = (parseFloat(this.form.grand_total) + parseFloat(this.form.vat_amount)) - parseFloat(discount)
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
